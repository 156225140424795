import store from '@/store/store'
import { BoardService } from '@/includes/services/BoardService'
import { Board } from '@/includes/types/Board.types'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'

export function setPostBoardConfig(board_key: Board['board'], boardConfig: Pick<Board, 'config' | 'lang' | 'timezone'>) {
    return BoardService.setPostBoardConfig('tg', {
            board_key: board_key,
            timezone: boardConfig.timezone,
            config: boardConfig.config,
            lang: boardConfig.lang,
        })
            .then(res => {
                store.commit('set_active_board_config', res)
                successNotification()

                return res
            })
            .catch(errorNotification)
}